import { useWindowSize } from 'usehooks-ts';
import { useQuantityPriorityItems } from '#/common/hooks/useQuantityPriorityItems';
import type { GenericProduct } from '../../types/generic-product';
import { ProductCard } from '../ProductCard';
import {
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  Carousel as CarouselPrimitive,
} from '../ui/carousel';
interface Props {
  products: GenericProduct[];
  priority?: boolean;
  label: string;
}

export function Carousel({ products, priority = false, label }: Props) {
  const { quantityPriorityItems } = useQuantityPriorityItems();
  const { width } = useWindowSize();

  const visibleProductsQtd = products.length > (width <= 768 ? 2 : 3);

  return (
    <CarouselPrimitive
      aria-label={label}
      opts={{
        loop: width > 768,
        align: 'start',
      }}
    >
      <CarouselContent>
        {products.map((product, index) => (
          <CarouselItem
            key={`${index}-${product.reference}`}
            className="mx-1 my-3 max-w-[220px] basis-3/4 cursor-pointer rounded-3xl sm:max-w-[280px] md:basis-2/4 lg:max-w-[340px] lg:basis-1/4"
          >
            <ProductCard product={product} />
          </CarouselItem>
        ))}
      </CarouselContent>
      {width > 768 && visibleProductsQtd && (
        <>
          <CarouselPrevious className="translate-x-9 2xl:translate-x-0" />
          <CarouselNext className="-translate-x-9 2xl:-translate-x-0" />
        </>
      )}
    </CarouselPrimitive>
  );
}
